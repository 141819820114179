import { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";

const getQuarterRange = (start, end) => {
    const quarters = ["Q1", "Q2", "Q3", "Q4"];
    const startYear = parseInt(start.reporting_year, 10);
    const endYear = parseInt(end.reporting_year, 10);
    const startQuarterIndex = quarters.indexOf(start.reporting_period.split(" ")[0]);
    const endQuarterIndex = quarters.indexOf(end.reporting_period.split(" ")[0]);

    let result = [];
    for (let year = startYear; year <= endYear; year++) {
        for (let q = 0; q < 4; q++) {
            if (
                (year === startYear && q < startQuarterIndex) ||
                (year === endYear && q > endQuarterIndex)
            ) {
                continue;
            }
            result.push({ reporting_year: year.toString(), reporting_period: quarters[q] });
        }
    }
    return result;
};

export const useQuarterlyDataRange = (endpoint, rangeFilter) => {
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { authState } = useOktaAuth();
    const bearerToken = authState?.accessToken?.accessToken;

    useEffect(() => {
        if (!rangeFilter || !bearerToken) return;

        const formattedRangeFilter = {
            start: {
                reporting_period: rangeFilter.start.reporting_period,
                reporting_year:
                    typeof rangeFilter.start.reporting_year === "string"
                        ? rangeFilter.start.reporting_year
                        : rangeFilter.start.reporting_year?.getFullYear().toString(),
            },
            end: {
                reporting_period: rangeFilter.end.reporting_period,
                reporting_year:
                    typeof rangeFilter.end.reporting_year === "string"
                        ? rangeFilter.end.reporting_year
                        : rangeFilter.end.reporting_year?.getFullYear().toString(),
            },
        };

        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(endpoint, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                        "Content-Type": "application/json",
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();

                const range = getQuarterRange(formattedRangeFilter.start, formattedRangeFilter.end);

                const filtered = data.filter((item) =>
                    range.some(
                        (rangeItem) =>
                            item.reporting_period.startsWith(rangeItem.reporting_period) &&
                            parseInt(item.reporting_year) === parseInt(rangeItem.reporting_year)
                    )
                );

                setFilteredData(filtered);
            } catch (err) {
                setError(err.message || "Error occurred while fetching data.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [endpoint, rangeFilter, bearerToken]);

    return { filteredData, loading, error };
};

import { useState, useEffect } from "react";
import {
  startOfMonth,
  endOfMonth,
  isWithinInterval,
  subMonths,
} from "date-fns";
import { useOktaAuth } from "@okta/okta-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button, ButtonGroup, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { HelpOutline, InfoOutlined } from "@mui/icons-material";

export function useGetSetData(endpoint, useMinStartDate = false) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDatePicker, setStartDatePicker] = useState(null);
  const [endDatePicker, setEndDatePicker] = useState(null);
  const [data, setData] = useState([]);
  const { authState } = useOktaAuth();
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;
  const [open, setOpen] = useState(false);
  const [openDefinitions, setDefinitionsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state for loading

  const renderWithLineBreaks = (text) => {
    const lines = text.split("\n");
    return lines.map((line, index) => (
      <div key={index}>
        {line}
        {index !== lines.length - 1 && <br />}{" "}
        {/* Add <br /> after each line except the last one */}
      </div>
    ));
  };
  const pageDisclaimers = {
    topDrugClassBreakdown:
      "This is a snapshot of a specific point-in-time; however, data is received on a regular basis that might alter these values.",
    dataMetrics: `This is a snapshot of a specific point-in-time; however, data is received on a regular basis that might alter these values. The reported information is based American Society for Automation in Pharmacy Standards qualifiers within a dispense record. 
    The information collected is not validated. For example, a patient may have the identification type listed as driver’s license, but the value may resolve to a state ID or unique identifier.`,
    registeredDispensers: `License information is collected from publicly available online sources, on a regular cadence and may not reflect information available from state licensing sources. 
    Information on exemption status and reporting accounts comes from pharmacy representatives. 
    Pharmacies listed as remaining may be compliant either submitting through another account not identified or in the process of documenting the exempt nature of their pharmacy.`,
    provisionedUsers: ` Licensee information is collected from publicly available online sources on a regular cadence and may not reflect information available from state licensing sources. Prescriber breakdown is on the reported license type during account creation or in subsequent interactions with the account holder and is not verified against a state licensing list.`,
    userQueries: `The searcher’s intent is not taken into consideration, “Jo* Sm* 1/1/00” and “John Smith 1/1/00” would constitute different searches. Searches for this metric would come in through the web portal. Queries through the RxCheck interface and from the HIE are excluded.`,

    mmeAlerts: `Patient Alert – When a patient is searched within the PDMP system, an alert is triggered. Multiple alerts may be triggered, but only if a patient is searched on different days. Unique Patient Alerts values – Generated from the maximum 7- or 30-day average.`,
    mpeAlerts: `Patient Alert – When a patient is found within the PDMP system and the alert criteria is met, an alert is triggered. 

    Pharmacies for the same company are considered distinct in this metric. 
 
    Prescribers within the same office are considered distinct prescribers in this metric. 
    
    Prescribers that utilize different DEA or NPI would be considered distinct prescribers in this metric.`,
    opioidBenzoOverlap: `Patient Alert – When a patient is found within the PDMP system and the alert criteria is met, an alert is triggered. 

    Does not distinguish between medication with a scheduled administration time or medication taken as needed.`,
    dispenseBreakdown: `This is a snapshot of a specific point-in-time; however, data is received on a regular basis that might alter these values.`,
    interIntra:
      "Searches listed as provided here still may go through a vetting process on the requestor side, and may not accurately reflect the number of successful queries from any integrated partner.",
    naloxoneDispenses: `This is a snapshot of a specific point-in-time; however, data is received on a regular basis that might alter these values. 

    Dispenses does not necessarily equate to the number of doses available for administration.`,
  };

  const pageDefinations = {
    topDrugClassBreakdown: renderWithLineBreaks(
      `GPI – Generic Product Identifier, a drug or therapeutic classification system of prescription medication used in the Medi-Span database from Wolters Kluwer. \n Drug Class – Second layer of classification from the medication’s GPI. This indicates a related medication by activity, generation, etc. `
    ),
    dataMetrics: renderWithLineBreaks(
      `ASAP – American Society for Automation in Pharmacy, the organization providing the standard for reporting pharmacy dispenses to PDMPs. \n Opioid Treatment Type – Classification system within the ASAP standards, to help determine the rationale for the opioid dispense and voluntarily reported by pharmacies.`
    ),
    registeredDispensers: renderWithLineBreaks(
      `Registered – Pharmacies with an active license or permit, and with a PDMP account listed as the submitter account. \nExempt – Pharmacies with an active license or permit, having filled out the self-attestation form they are not required to submit dispenses pursuant to Neb. Rev. Stat. § 71-2454(4) and Neb. Rev. Stat. § 71-2454(14)(d). \nRemaining – Pharmacies with an active license or permit, without exemption information entered or an identified PDMP account.`
    ),
    provisionedUsers: renderWithLineBreaks(
      `Prescribers – Licensed individuals in the following categories (Physician, Dentist, Optometrist, Podiatrist, Physician Assistant, or Advanced Practice RN). \nPrescriber Delegate – Licensed or registered individuals according to the Nebraska Uniform Credentialing Act utilizing a physician as the role delegating search capabilities. These may include nurses or EMS individuals. \nDispensers – Role type for pharmacists within the RxGov application. \n  Pharmacist Delegate – Licensed individuals according to the Nebraska Uniform Credentialing Act utilizing a pharmacist (dispenser) as the role delegating search capabilities. These primarily include pharmacy technicians and pharmacist interns but may include other licensed individuals. \nEnrolled (Prescribers / Pharmacists) – Active accounts for the role selected. \nEligible (Prescribers / Pharmacists – Number of licensees computed from publicly available online sources. \nTotal – Total of enrolled prescribers, pharmacists, and their respective delegates.`
    ),
    userQueries: renderWithLineBreaks(
      `User Query – The entered name and date of birth used during a PDMP query.  \nMonthly Queries – A summary of any User Query from the reporting month. \nUser Queried on a Daily Basis – A summary of unique users searched on, for any day. Includes the same information searched on different days.`
    ),
    mmeAlerts: renderWithLineBreaks(
      `MME – Morphine milligram equivalents, a metric to view all opioid dispenses in a common factor. \nMME/day – Metric used to gauge the daily amount of all prescribed opioids per day in order to determine overdose potential. \n7-day and 30-day average – MME values at a point in time triggered by the searching of a specific patient on a specific day.`
    ),
    mpeAlerts: renderWithLineBreaks(
      `MPE – Multiple provider or pharmacy encounter for opioid prescriptions. \nPrescriber – Based on a combination of factors, including name, NPI and DEA numbers, a unique individual. \nPharmacy – Based on the reported pharmacy identification; NPI, DEA, and/or NCPDP, a unique pharmacy. \nAlert metric -- For a patient with an opioid prescription within the last 180 days, the number of distinct prescribers or pharmacies servicing the patient when dispensing an opioid prescription.`
    ),
    opioidBenzoOverlap: renderWithLineBreaks(
      `Opioid and Benzodiazepine overlap alert – Any patient, within the last 45 days, has an opioid prescription and a benzodiazepine prescription overlap of at least one (1) day based on the respective prescriptions date filled and days’ supply. \n Opioid and Benzodiazepine overlap – Drug classes that when taken together increases the risk of an overdose.`
    ),
    dispenseBreakdown: renderWithLineBreaks(
      `Controlled Substance Schedule – A DEA classification of substances, including prescriptions, kept at the state and federal level. This classification takes into consideration accepted medical use, relative abuse potential, and likelihood of causing dependence when abused. \nC2, C3, C4, C5 – The schedule assigned the medication by the DEA, with schedule 2 (C2) being the second highest category for risk of abuse. Schedule 1 medications are not reported as their mechanism for dispensing, and use is outside the scope of the Nebraska PDMP data collection. \nNon-Controlled Substances – Any dispense, that when the product information is compared against the PDMP metadata source, does not return a value from the DEA Schedule of medications but is identified. \nUnknown – Dispenses with product identifiers that are not found within the PDMP metadata source. This may include medical equipment, over-the-counter products, compounded products, and line-item added at the pharmacy, like delivery or packaging charges, disguised as dispenses due to software limitations.`
    ),
    interIntra: renderWithLineBreaks(
      `Incoming Queries – Patient queries initiated by an out-of-state PDMP, requesting information from the Nebraska PDMP. \nOutgoing Queries – Patient queries initiated by an in-state user requesting information from another state’s PDMP. \nWorkflow Integration (WFI) Queries – Queries requested by healthcare entities connected to the Nebraska PDMP, such as Nebraska Medicine queries through their EHR or a query from a pharmacy using Abacus pharmacy software or a third-party integrator (e.g., Logicoy, DrFirst). \nProvided – The search criteria returned at least one patient. \nNot Found – The search criteria returned no patients. \nNo Access – A collection of statuses including access denied, connection timed out, and connection not valid, returned when the query failed.`
    ),
    naloxoneDispenses: renderWithLineBreaks(
      `# of Unique / Individual patients – The raw number of individuals, as determined by the PDMP, receiving the medication.`
    ),
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenDefinitions = () => {
    setDefinitionsOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDefinitionsClose = () => {
    setDefinitionsOpen(false);
  };
  function convertDatesToUnix(data) {
    return data.map((obj) => {
      const { date: dateString, ...rest } = obj;
      const [month, year] = dateString.split(" ");
      const parsedDate = new Date(`${month} 01, ${year}`);
      const unixDate = parsedDate.getTime(); // Convert to Unix timestamp (in milliseconds)
      return { Date: Math.floor(unixDate), ...rest }; // Convert to integer
    });
  }

  const fetchData = async () => {
    try {
      setIsLoading(true); // Set loading to true before fetching data
      const response = await fetch(endpoint, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const jsonData = await response.json();
        const convertedData = convertDatesToUnix(jsonData);
        setData(convertedData);

        const maxDate = startOfMonth(
          convertedData[convertedData.length - 1]?.Date
        );
        const twelveMonthsAgo = subMonths(maxDate, 11);
        const minDate = useMinStartDate
          ? startOfMonth(convertedData[0]?.Date)
          : twelveMonthsAgo;

        setStartDate(minDate);
        setEndDate(maxDate);
        setStartDatePicker(minDate);
        setEndDatePicker(maxDate);
      } else {
        throw new Error("Failed to fetch data. Status: " + response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally {
      setIsLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    if (bearerToken) {
      fetchData();
    }
  }, [bearerToken]);

  const minDate = startOfMonth(data[0]?.Date);
  const maxDate = startOfMonth(data[data.length - 1]?.Date);

  const filteredData = data
    .filter((data) => {
      if (!startDate || !endDate) {
        return true; // No date range selected, include all data
      }

      const interval = {
        start: startOfMonth(startDate),
        end: endOfMonth(endDate),
      };
      return isWithinInterval(data.Date, interval);
    })
    .sort((a, b) => {
      // Convert the date strings to actual Date objects for comparison
      const dateA = new Date(a.Date);
      const dateB = new Date(b.Date);
      // Compare the dates in descending order
      return dateB - dateA;
    })
    .map((data, index) => ({
      ...data,
      id: index + 1, // You can use any unique ID generation logic here.
    }));

  const handleSubmit = () => {
    setEndDate(endDatePicker);
    setStartDate(startDatePicker);
  };

  const datePickersComponent = (
    <div style={{ marginBottom: "10px" }}>
      <Grid container spacing={1} alignItems="center">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item>
            <DatePicker
              views={["year", "month"]}
              label="Start Date"
              value={startDate}
              minDate={minDate} // Set the minimum allowed date
              maxDate={endDate}
              onChange={(date) => setStartDate(date)}
            />
          </Grid>
          <Grid item>
            <DatePicker
              views={["year", "month"]}
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              minDate={startDate}
              maxDate={maxDate}
            />
          </Grid>
        </LocalizationProvider>
        {/* <Grid item style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" onClick={handleSubmit} size="small">
            Filter
          </Button>
        </Grid> */}
      </Grid>
    </div>
  );

  const disclaimerComponent = (page) => {
    return (
      <>
        <ButtonGroup
          variant="outlined"
          aria-label="outlined primary button group"
        >
          <Button
            onClick={handleClickOpen}
            size="small"
            endIcon={<InfoOutlined />}
          >
            Disclaimer
          </Button>
          <Button
            onClick={handleClickOpenDefinitions}
            size="small"
            endIcon={<HelpOutline />}
          >
            Definitions
          </Button>
        </ButtonGroup>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Disclaimer </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {pageDisclaimers[page]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDefinitions}
          onClose={handleDefinitionsClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Definitions</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {pageDefinations[page]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDefinitionsClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const yearPickersComponent = (
    <div style={{ marginBottom: "10px" }}>
      <Grid container spacing={1} alignItems="center">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item>
            <DatePicker
              views={["year", "month"]}
              label="Start Date"
              value={startDate}
              minDate={minDate} // Set the minimum allowed date
              maxDate={maxDate}
              onChange={(date) => setStartDatePicker(date)}
            />
          </Grid>
          <Grid item>
            <DatePicker
              views={["year", "month"]}
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDatePicker(date)}
              minDate={startDatePicker}
              maxDate={maxDate}
            />
          </Grid>
        </LocalizationProvider>
        <Grid item style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" onClick={handleSubmit} size="small">
            Filter
          </Button>
        </Grid>
      </Grid>
    </div>
  );
  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startDatePicker,
    setStartDatePicker,
    endDatePicker,
    setEndDatePicker,
    data,
    filteredData,
    minDate,
    maxDate,
    datePickersComponent,
    yearPickersComponent,
    disclaimerComponent,
    isLoading, // Expose the loading state

  };
}

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
} from "@mui/material";
import { useGetSetData } from "../../services/useGetSetHook";
import { format } from "date-fns";

const BjaMonthlyData = () => {
  // Fetch data using the custom hook
  const { filteredData, datePickersComponent, isLoading } =
    useGetSetData(
      process.env.REACT_APP_BASE_API + "/bja/mv_bja_monthly_data",
      false
    );

  // Helper function to format the date
  const formatDate = (timestamp) => format(new Date(timestamp), "MMM yyyy");

  // Render table dynamically
  const renderTable = (data) => {
    // Extract unique keys for table headers dynamically
    const tableHeaders = [
      "Date",
      ...Object.keys(data[0] || {}).filter(
        (key) => key !== "Date" && key !== "last_date_run" && key !== "id"
      ),
    ];

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell key={header} sx={{ fontWeight: "bold" }}>
                  {header.replace(/_/g, " ").toUpperCase()}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {tableHeaders.map((key) => (
                  <TableCell key={key}>
                    {key === "Date"
                      ? formatDate(row[key]) // Format the Date field
                      : row[key] !== null && row[key] !== undefined
                      ? row[key]
                      : "N/A"}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={10}>
          {datePickersComponent}
        </Grid>
        {/* <Grid item xs={12} md={2}>
          {disclaimerComponent("pdmpMetrics")}
        </Grid> */}
      </Grid>
      <div style={{ width: "88vw", overflowX: "none" }}>
        {isLoading ? ( // Show spinner while loading
          <Box display="flex" justifyContent="center" alignItems="center" height="100px">
            <CircularProgress />
          </Box>
        ) : filteredData && filteredData.length > 0 ? (
          renderTable(filteredData)
        ) : (
          <Typography textAlign="center">No data available</Typography>
        )}
      </div>
    </div>
  );
};

export default BjaMonthlyData;